export default function Card({ card }: any) {
  return (
    <div key={card.name} className="flex flex-col rounded-2xl bg-white shadow-xl">
      <div className="relative flex-1 px-6 pt-16 pb-8 md:px-8">
        <div className="absolute top-0 inline-block -translate-y-1/2 transform rounded-xl bg-sky-600 p-5 shadow-lg">
          <card.icon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <h3 className="text-xl font-medium text-gray-900">{card.name}</h3>
        <p className="mt-4 text-base text-gray-500">{card.description}</p>
      </div>
      <div className="rounded-bl-2xl rounded-br-2xl bg-gray-50 p-6 md:px-8">
        <a href={card.href} className="text-base font-medium text-sky-700 hover:text-sky-600">
          Contact us<span aria-hidden="true"> &rarr;</span>
        </a>
      </div>
    </div>
  )
}
