// pages/index.js
import Head from 'next/head'
import ApplicationShell from '../shared/components/ApplicationShell'
import ImagePlusCards from '../shared/components/ImagePlusCards'
import SimpleCenteredHero from '../shared/components/SimpleCenteredHero'
import { CodeIcon, CogIcon, DesktopComputerIcon } from '@heroicons/react/outline'
import Testimonial from '../shared/components/Testimonial'
import Post from '../shared/types/post'
import { getAllPosts } from '../shared/lib/api'

const cards = [
  {
    name: 'Computer Repair',
    href: '/repair',
    description:
      'We offer a wide range of repair services for all types of computers and peripherals. Problems with your internet? Forgot your password? We can fix it!',
    icon: CogIcon,
  },
  {
    name: 'Business IT Services',
    href: '/business-it',
    description:
      'We understand that businesses are constantly changing and evolving. We can help you manage your IT infrastructure to ensure your business continues to run smoothly.',
    icon: DesktopComputerIcon,
  },
  {
    name: 'Web / Software Development',
    href: '/develop',
    description:
      'Ready to build a new website? We can help you build a custom solution for your business. We can also build phone and desktop applications. Dream it, we can build it!',
    icon: CodeIcon,
  },
]

type Props = {
  allPosts: Post[]
}

export default function Home({ allPosts }: Props) {
  return (
    <div>
      <Head>
        <title>Rune - Technology Services</title>
        <meta
          name="description"
          content="Rune provides computer repair, business IT, and website / software development. We guarantee we can help. Give us a call to learn the Rune way."
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <ApplicationShell current={'Home'}>
        <SimpleCenteredHero
          heroPrimary="Rune."
          heroSecondary="Chico, CA."
          heroBrief="Trusted local computer repair, business IT, and web / software development. We bring premium technology solutions to our community. We guarantee we can fix your problem and help you get the most out of your technology."
        />
        <ImagePlusCards
          imageHeroText="Support"
          imageHeroSubtext="We all need a little help when we're stuck. Get the support you need to thrive from our team of experts. Contact us today and get peace of mind from our friendly, knowledgeable staff."
          cards={cards}
        />
        <Testimonial />
      </ApplicationShell>
    </div>
  )
}

export const getStaticProps = async () => {
  const allPosts = getAllPosts([
    'title',
    'date',
    'slug',
    'author',
    'previewImage',
    'previewVideo',
    'excerpt',
    'readingTime',
  ])

  return {
    props: { allPosts },
  }
}
