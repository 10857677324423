import { ChevronRightIcon } from '@heroicons/react/outline'
import Link from 'next/link'

export default function SimpleCenteredHero({
  heroPrimary = 'Premier tech service',
  heroSecondary = 'Chico, CA',
  heroBrief = "Bring your problems to us and we'll take care of it. We're here to help you get the most out of your computer.",
  bg = 'gray-50',
  children,
  buttons = true,
}: any) {
  return (
    <div className={`relative bg-${bg} overflow-hidden`}>
      <div className="hidden sm:absolute sm:inset-y-0 sm:block sm:h-full sm:w-full" aria-hidden="true">
        <div className="relative mx-auto h-full max-w-7xl">
          <svg
            className="absolute right-full translate-y-1/4 translate-x-1/4 transform lg:translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          <svg
            className="absolute left-full -translate-y-3/4 -translate-x-1/4 transform md:-translate-y-1/2 lg:-translate-x-1/2"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
          >
            <defs>
              <pattern
                id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />
          </svg>
        </div>
      </div>

      <div className="relative pt-6 pb-16 sm:pb-24">
        <main className="mx-auto mt-16 max-w-7xl px-4 sm:mt-24">
          <div className="text-center">
            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline">{heroPrimary}</span>{' '}
              <span className="block text-sky-600 xl:inline">{heroSecondary}</span>
            </h1>
            <p className="mx-auto mt-3 max-w-md text-base text-gray-500 sm:text-lg md:mt-5 md:max-w-3xl md:text-xl">
              {heroBrief}
            </p>
            {buttons ? (
              <div className="mx-auto mt-5 max-w-md sm:flex sm:justify-center md:mt-8">
                <div className="rounded-md shadow">
                  <Link href="/contact" passHref>
                    <span className="flex w-full cursor-pointer items-center justify-center rounded-md border border-transparent bg-sky-600 px-8 py-3 text-base font-medium text-white hover:bg-sky-700 md:py-4 md:px-10 md:text-lg">
                      Get started
                    </span>
                  </Link>
                </div>
                <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                  <Link href="/appointment" passHref>
                    <span className="flex w-full cursor-pointer items-center justify-center rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-sky-600 hover:bg-gray-50 md:py-4 md:px-10 md:text-lg">
                      Live demo
                    </span>
                  </Link>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="mx-auto mt-3 flex max-w-md justify-center md:max-w-3xl">{children}</div>
          </div>
        </main>
      </div>
    </div>
  )
}
